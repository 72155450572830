import { useEffect } from 'react'
import { usePriceCakeBusd } from 'state/hooks'

const useGetDocumentTitlePrice = () => {
  // const cakePriceUsd = usePriceCakeBusd()
  // useEffect(() => {
  //   console.log("title", cakePriceUsd.toString())
  // })
}
export default useGetDocumentTitlePrice
