import { configureStore } from '@reduxjs/toolkit'
// import farmsReducer from './farms'
import farmsV2Reducer from './farmsV2'
import toastsReducer from './toasts'
import poolsReducer from './pools'
// import pricesReducer from './prices'
import profileReducer from './profile'
import teamsReducer from './teams'
import achievementsReducer from './achievements'
import blockReducer from './block'
import rewardPoolReducer from './rewardPool'

export default configureStore({
  devTools: process.env.NODE_ENV !== 'production',
  reducer: {
    // farms: farmsReducer,
    farmsV2: farmsV2Reducer,
    toasts: toastsReducer,
    pools: poolsReducer,
    // prices: pricesReducer,
    profile: profileReducer,
    teams: teamsReducer,
    achievements: achievementsReducer,
    block: blockReducer,
    rewardPool: rewardPoolReducer,
  },
})
