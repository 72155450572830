import { createSlice } from '@reduxjs/toolkit'
import { RewardPool } from '../types'
import {fetchRewardPool} from "./fetchRewardPool";


const initialState: RewardPool = { 
}

export const rewardPoolSlice = createSlice({
  name: 'RewardPool',
  initialState,
  reducers: {
    setRewardPool: (state, action) => ({ ...state, ...action.payload }),
    setRewardPoolUserData: (state, action) => ({...state, userData: action.payload})
  },
})

// Actions
export const { setRewardPool } = rewardPoolSlice.actions

// Thunks


export const fetchRewardPoolAsync = () => async (dispatch) => {
  const poolInfo = await fetchRewardPool();
  dispatch(setRewardPool(poolInfo));
};


export default rewardPoolSlice.reducer

