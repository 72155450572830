/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";
import farmsConfig from "config/constants/farms";
import BigNumber from "bignumber.js";
import fetchFarms from "./fetchFarms";

import {
  fetchFarmUserEarnings,
  fetchFarmUserAllowances,
  fetchFarmUserTokenBalances,
  fetchFarmUserStakedBalances,
} from "./fetchFarmUser";
import { FarmsState, Farm } from "../types";

const initialState: FarmsState = {
  data: [...farmsConfig],
  rewardTokenPerBlock: "0",
};

export const farmsSlice = createSlice({
  name: "Farms",
  initialState,
  reducers: {
    setFarmsPublicData: (state, action) => {
      const liveFarmsData: Farm[] = action.payload;
      state.data = state.data.map((farm) => {
        const liveFarmData = liveFarmsData.find((f) => f.pid === farm.pid);
        return { ...farm, ...liveFarmData };
      });
    },
    setFarmPublicData: (state, action) => {
      const rewardTokenPerBlock: string = action.payload;
      state.rewardTokenPerBlock = rewardTokenPerBlock;
    },
    setFarmUserData: (state, action) => {
      const { arrayOfUserDataObjects } = action.payload;
      arrayOfUserDataObjects.forEach((userDataEl) => {
        const { index } = userDataEl;
        state.data[index] = { ...state.data[index], userData: userDataEl };
      });
    },
  },
});

// Actions
export const { setFarmsPublicData, setFarmUserData, setFarmPublicData } =
  farmsSlice.actions;

// Thunks
// export const fetchFarmsPublicDataAsync = () => async (dispatch) => {
//   console.log("fetchfarmpublicdataasync");
//   const farms = await fetchFarms();
//   console.log("farms fetched public data ", farms);
//   dispatch(setFarmsPublicData(farms));
// };

export const fetchFarmPublicDataAsync = (farmContract) => async (dispatch) => {
  const rewardTokenPerBlock = await (
    await farmContract?.methods.rewardTokenPerBlock.call()
  ).call();
  const tokenToString = new BigNumber(rewardTokenPerBlock)
    .div(new BigNumber(10).pow(18))
    .toString();
  dispatch(setFarmPublicData(tokenToString));
};
export const fetchFarmUserDataAsync = (account) => async (dispatch) => {
  let userFarmAllowances = [];
  let userFarmTokenBalances = [];
  let userStakedBalances = [];
  let userFarmEarnings = [];
  try {
    userFarmAllowances = await fetchFarmUserAllowances(account);
  } catch (e) {
    console.log("fetchFarmUserAllowances", e);
  }
  try {
    userFarmTokenBalances = await fetchFarmUserTokenBalances(account);
  } catch (e) {
    console.log("fetchFarmUserTokenBalances", e);
  }
  try {
    userStakedBalances = await fetchFarmUserStakedBalances(account);
  } catch (e) {
    console.log("fetchFarmUserStakedBalances", e);
  }
  try {
    userFarmEarnings = await fetchFarmUserEarnings(account);
  } catch (e) {
    console.log("fetchFarmUserEarnings", e);
  }

  const arrayOfUserDataObjects = userFarmAllowances.map(
    (farmAllowance, index) => {
      return {
        index,
        allowance: userFarmAllowances[index],
        tokenBalance: userFarmTokenBalances[index],
        stakedBalance: userStakedBalances[index],
        earnings: userFarmEarnings[index],
      };
    },
  );

  dispatch(setFarmUserData({ arrayOfUserDataObjects }));
};

export default farmsSlice.reducer;
