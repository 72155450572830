import contracts from "./contracts";
import { FarmConfig, FarmV2Config, QuoteToken } from "./types";

const TREASURE_RINKEBY = "0x9afF3dBB409AcE1B9441200E77183B2C6dE210CB"
const TREASURE_MAIN = '0xf7ae26f1bf3c2312a4cf42246f947a4be25eef92';

const LP_RINKEBY = "0x58460ab9170e48b0c4ccc83dffdfd033eb3f3ad5"
const LP_MAIN = "0x4f05bbd85daba22f43bbf58ac98e61be240d3a77"


const farms: FarmConfig[] = [
  {
    pid: 0,
    lpSymbol: "XPLAY",
    lpAddresses: {
      4: "",
      1: TREASURE_MAIN, // XPLAY token address
    },
    tokenSymbol: "XPLAY",
    tokenAddresses: {
      4: "",
      1: TREASURE_MAIN, // XPLAY syrup token
    },
    quoteTokenSymbol: QuoteToken.CAKE,
    quoteTokenAdresses: {
      4: "",
      1: TREASURE_MAIN, // XPLAY syrup token
    },
  },

  {
    pid: 1,
    lpSymbol: "XPLAY-BNB LP",
    lpAddresses: {
      4: "",
      1: "0xB4E34e0f46a8AacEB7237cCEF41ef565FD3E81FD", // lp address XPLAY-bnb
    },
    tokenSymbol: "XPLAY",
    tokenAddresses: {
      4: "",
      1: TREASURE_MAIN, // XPLAY token address
    },
    quoteTokenSymbol: QuoteToken.ETH,
    quoteTokenAdresses: contracts.wbnb,
  },
  {
    pid: 2,
    lpSymbol: "X2P-BNB LP",
    lpAddresses: {
      4: "",
      1: "0x2db359Bd40f7547236fE0264344450AB7fB47689", // lp address x2p-bnb
    },
    tokenSymbol: "X2P",
    tokenAddresses: {
      4: "",
      1: "0xdfd85305A752d70A07A995Fa724Ac4Ff787EBef2", // X2P token address
    },
    quoteTokenSymbol: QuoteToken.ETH,
    quoteTokenAdresses: contracts.wbnb,
  },
  {
    pid: 3,
    lpSymbol: "X2P",
    lpAddresses: {
      4: "",
      1: "0xdfd85305A752d70A07A995Fa724Ac4Ff787EBef2", // lp address x2p-bnb
    },
    tokenSymbol: "X2P",
    tokenAddresses: {
      4: "",
      1: "0xdfd85305A752d70A07A995Fa724Ac4Ff787EBef2", // X2P token address
    },
    quoteTokenSymbol: QuoteToken.X2P,
    quoteTokenAdresses: {
      4: "",
      1: "0xdfd85305A752d70A07A995Fa724Ac4Ff787EBef2", // XPLAY syrup token
    },
  },
];

export const farmsV2Config: FarmV2Config[] = [
  {
    farmAddress: {
      4: "0x16AEabFC6A1C3Cc44829A1Fbc44D9b68f85d082a",
      1: "0x0247650Ee070768601831B19920bAa8Cac6A2C8c",
    },
    lpSymbol: "Treasure-ETH LP 7 DAY",
    lpAddresses: { // Token being staked
      4: LP_RINKEBY,
      1: LP_MAIN,
    },
    tokenSymbol: "TREASURE-ETH",
    tokenAddresses: { // Reward Token
      4: TREASURE_RINKEBY,
      1: TREASURE_MAIN, 
    },
    quoteTokenSymbol: QuoteToken.ETH,
    quoteTokenAdresses: contracts.wbnb,
    earlyWithdrawalSeconds: 604800,
  },
  {
    farmAddress: {
      4: "0x16AEabFC6A1C3Cc44829A1Fbc44D9b68f85d082a",
      1: "0xF8Ed124B4120B683f50bD1D7d2b9458ed862A2c9",
    },
    lpSymbol: "Treasure-ETH LP 14 DAY",
    lpAddresses: { // Token being staked
      4: LP_RINKEBY,
      1: LP_MAIN,
    },
    tokenSymbol: "TREASURE-ETH",
    tokenAddresses: { // Reward Token
      4: TREASURE_RINKEBY,
      1: TREASURE_MAIN, 
    },
    quoteTokenSymbol: QuoteToken.ETH,
    quoteTokenAdresses: contracts.wbnb,
    earlyWithdrawalSeconds: 1209600,
  },
  {
    farmAddress: {
      4: "0x860aC9EE5Cf4da65Dd395D094f8139D1296E3BCa",
      1: "0x820A3f488Dd54B87E1Dc78ef484a9C1a27d8b82B",
    },
    lpSymbol: "TREASURE 7-day",
    lpAddresses: {
      4: TREASURE_RINKEBY,
      1: TREASURE_MAIN, 
    },
    tokenSymbol: "TREASURE",
    tokenAddresses: {
      4: TREASURE_RINKEBY,
      1: TREASURE_MAIN, 
    },
    quoteTokenSymbol: QuoteToken.CAKE,
    quoteTokenAdresses: {
      4: TREASURE_RINKEBY,
      1: TREASURE_MAIN, 
    },
    earlyWithdrawalSeconds: 604800,
  },
  {
    farmAddress: {
      4: "",
      1: "0x18A628f0f72a26142f6f68C0bB97101FF6dD9ce1",
    },
    lpSymbol: "TREASURE 14-day",
    lpAddresses: {
      4: TREASURE_RINKEBY,
      1: TREASURE_MAIN, 
    },
    tokenSymbol: "TREASURE",
    tokenAddresses: {
      4: TREASURE_RINKEBY,
      1: TREASURE_MAIN, 
    },
    quoteTokenSymbol: QuoteToken.CAKE,
    quoteTokenAdresses: {
      4: TREASURE_RINKEBY,
      1: TREASURE_MAIN, 
    },
    earlyWithdrawalSeconds: 1209600,
  },
  {
    farmAddress: {
      4: "",
      1: "0xa90F8A2C35A2be87BA000fD1ad59921d0056220f",
    },
    lpSymbol: "TREASURE 30-day",
    lpAddresses: {
      4: TREASURE_RINKEBY,
      1: TREASURE_MAIN, 
    },
    tokenSymbol: "TREASURE",
    tokenAddresses: {
      4: TREASURE_RINKEBY,
      1: TREASURE_MAIN, 
    },
    quoteTokenSymbol: QuoteToken.CAKE,
    quoteTokenAdresses: {
      4: TREASURE_RINKEBY,
      1: TREASURE_MAIN, 
    },
    earlyWithdrawalSeconds: 2584000,
  },
  {
    farmAddress: {
      4: "",
      1: "0x3e14d4397D889C6D0E6825eF63A25800627282Ba",
    },
    lpSymbol: "TREASURE 60-day",
    lpAddresses: {
      4: TREASURE_RINKEBY,
      1: TREASURE_MAIN, 
    },
    tokenSymbol: "TREASURE",
    tokenAddresses: {
      4: TREASURE_RINKEBY,
      1: TREASURE_MAIN, 
    },
    quoteTokenSymbol: QuoteToken.CAKE,
    quoteTokenAdresses: {
      4: TREASURE_RINKEBY,
      1: TREASURE_MAIN, 
    },
    earlyWithdrawalSeconds: 5168600,
  }
];

export default farms;
