import React, { useEffect, useState } from 'react'
import { AbstractConnector } from '@web3-react/abstract-connector'
import { UnsupportedChainIdError, useWeb3React } from '@web3-react/core'
import { WalletConnectConnector } from '@web3-react/walletconnect-connector'
import { isMobile } from 'react-device-detect'
import styled from 'styled-components/macro'
import MetamaskIcon from 'assets/images/metamask.png'
import { ReactComponent as Close } from 'assets/images/x.svg'
import { injected } from 'utils/connectors'
import { SUPPORTED_WALLETS } from 'utils/wallet'
import usePrevious from 'hooks/usePrevious'
import { Modal } from '@pancakeswap-libs/uikit'
// import Modal from './Modal'
import Option from './Option'
import PendingView from './Pending'

const CloseIcon = styled.div`
  position: absolute;
  right: 1rem;
  top: 14px;
  &:hover {
    cursor: pointer;
    opacity: 0.6;
  }
`

const CloseColor = styled(Close)``

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 0;
  width: 100%;
`

const HeaderRow = styled.div`
  display: flex;
  flex-direction: row;
  padding: 1rem 1rem;
  font-weight: 500;
  color: ${(props) => (props.color === 'blue' ? ({ theme }) => theme.colors.primary : 'inherit')};
`

const ContentWrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.background};
  padding: 0 1rem 1rem 1rem;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
`

const UpperSection = styled.div`
  position: relative;

  h5 {
    margin: 0;
    margin-bottom: 0.5rem;
    font-size: 1rem;
    font-weight: 400;
  }

  h5:last-child {
    margin-bottom: 0px;
  }

  h4 {
    margin-top: 0;
    font-weight: 500;
  }
`

const OptionGrid = styled.div`
  display: grid;
  grid-gap: 10px;
  grid-template-columns: 1fr;
  grid-gap: 10px;
`

const HoverText = styled.div`
  text-decoration: none;
  color: ${({ theme }) => theme.colors.text};
  display: flex;
  align-items: center;

  :hover {
    cursor: pointer;
  }
`

const WALLET_VIEWS = {
  OPTIONS: 'options',
  OPTIONS_SECONDARY: 'options_secondary',
  ACCOUNT: 'account',
  PENDING: 'pending',
}

const defaultOnDismiss = () => null

export default function WalletModal({ onDismiss = defaultOnDismiss }: { onDismiss?: () => null }) {
  // important that these are destructed from the account-specific web3-react context
  const { active, account, connector, activate, error } = useWeb3React()

  const [walletView, setWalletView] = useState(WALLET_VIEWS.ACCOUNT)

  const [pendingWallet, setPendingWallet] = useState<AbstractConnector | undefined>()

  const [pendingError, setPendingError] = useState<boolean>()

  const previousAccount = usePrevious(account)

  // close modal when a connection is successful
  const activePrevious = usePrevious(active)
  const connectorPrevious = usePrevious(connector)

  const tryDeactivate = async (_connector: AbstractConnector | undefined) => {
    if (!_connector){
      return 
    }
    try {
      window.localStorage.removeItem('walletconnect')
      window.localStorage.removeItem('wallet')
      window.localStorage.removeItem('-walletlink:https://www.walletlink.org:walletUsername')
      window.localStorage.removeItem('-walletlink:https://www.walletlink.org:session:id')
      window.localStorage.removeItem("-walletlink:https://www.walletlink.org:Addresses")
      window.localStorage.removeItem("-walletlink:https://www.walletlink.org:secret")
      window.localStorage.removeItem("-walletlink:https://www.walletlink.org:linked")
      
      if (
        (_connector instanceof WalletConnectConnector && _connector.walletConnectProvider?.wc?.uri) ||
        window.localStorage.getItem('walletconnect')
      ) {
        // @ts-ignore
        _connector.walletconnect.close()
        // @ts-ignore
        // _connector.walletconnect.walletConnectProvider = null
      }
    } catch (e) {
      console.log('tryDisconnect error', e)
    }
  }

  const tryActivation = async (_connector: AbstractConnector | undefined) => {
    setPendingWallet(_connector) // set wallet for pending view
    setWalletView(WALLET_VIEWS.PENDING)
    tryDeactivate(_connector)

    // if the connector is walletconnect and the user has already tried to connect, manually reset the connector
    if (_connector instanceof WalletConnectConnector && _connector.walletConnectProvider?.wc?.uri) {
      // _connector.walletConnectProvider = undefined
    }
    return (
      _connector &&
      activate(_connector, undefined, true)
        .then(() => {
          onDismiss()
        })
        .catch((err) => {
          if (err instanceof UnsupportedChainIdError) {
            activate(_connector) // a little janky...can't use setError because the connector isn't set
          } else {
            setPendingError(true)
          }
        })
    )
  }

  // get wallets user can switch too, depending on device/browser
  function getOptions() {
    // @ts-ignore
    const isMetamask = window.ethereum && window.ethereum.isMetaMask
    return Object.keys(SUPPORTED_WALLETS).map((key) => {
      const option = SUPPORTED_WALLETS[key]
      // check for mobile options
      if (isMobile) {
        // disable portis on mobile for now

        if (option.mobile) {
          return (
            <Option
              onClick={() => {
                if (option.connector !== connector && !option.href) {
                  tryActivation(option.connector)
                }
              }}
              id={`connect-${key}`}
              key={key}
              active={option.connector && option.connector === connector}
              color={option.color}
              link={option.href}
              header={option.name}
              subheader={null}
              icon={option.iconURL}
            />
          )
        }
        return null
      }

      // overwrite injected when needed
      if (option.connector === injected) {
        // don't show injected if there's no injected provider
        // @ts-ignore
        if (!(window.web3 || window.ethereum)) {
          if (option.name === 'MetaMask') {
            return (
              <Option
                id={`connect-${key}`}
                key={key}
                color="#E8831D"
                header={<text>Install Metamask</text>}
                subheader={null}
                link="https://metamask.io/"
                icon={MetamaskIcon}
              />
            )
          }
          return null // dont want to return install twice
        }
        // don't return metamask if injected provider isn't metamask
        if (option.name === 'MetaMask' && !isMetamask) {
          return null
        }
        // likewise for generic
        if (option.name === 'Injected' && isMetamask) {
          return null
        }
      }

      // return rest of options
      return (
        !isMobile &&
        !option.mobileOnly && (
          <Option
            id={`connect-${key}`}
            onClick={() => {
              if (option.connector === connector) {
                setWalletView(WALLET_VIEWS.ACCOUNT)
              } else if (!option.href) {
                tryActivation(option.connector)
              }
            }}
            key={key}
            active={option.connector === connector}
            color={option.color}
            link={option.href}
            header={option.name}
            subheader={null} // use option.descriptio to bring back multi-line
            icon={option.iconURL}
          />
        )
      )
    })
  }

  function getModalContent() {
    if (error) {
      return (
        <UpperSection>
          <CloseIcon onClick={onDismiss}>
            <CloseColor />
          </CloseIcon>
          <HeaderRow>
            {error instanceof UnsupportedChainIdError ? <text>Wrong Network</text> : <text>Error connecting</text>}
          </HeaderRow>
          <ContentWrapper>
            {error instanceof UnsupportedChainIdError ? (
              <h5>
                <text>Please connect to the appropriate Ethereum network.</text>
              </h5>
            ) : (
              <text>Error connecting. Try refreshing the page.</text>
            )}
          </ContentWrapper>
        </UpperSection>
      )
    }
    return (
      <UpperSection>
        <CloseIcon onClick={onDismiss}>
          <CloseColor />
        </CloseIcon>
        {walletView !== WALLET_VIEWS.ACCOUNT ? (
          <HeaderRow color="blue">
            <HoverText
              onClick={() => {
                setPendingError(false)
                setWalletView(WALLET_VIEWS.ACCOUNT)
              }}
            >
              Back
            </HoverText>
          </HeaderRow>
        ) : (
          <HeaderRow>
            <HoverText>
              Connect to a wallet
            </HoverText>
          </HeaderRow>
        )}
        <ContentWrapper>
          {walletView === WALLET_VIEWS.PENDING ? (
            <PendingView
              connector={pendingWallet}
              error={pendingError}
              setPendingError={setPendingError}
              tryActivation={tryActivation}
            />
          ) : (
            <OptionGrid>{getOptions()}</OptionGrid>
          )}
        </ContentWrapper>
      </UpperSection>
    )
  }

  return (
    <Modal title="Connect Wallet" onDismiss={onDismiss}>
      <Wrapper>{getModalContent()}</Wrapper>
    </Modal>
  )
}
