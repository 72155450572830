import { Address } from './types'



const rewardPoolByAddress: Address = {
  1: '0x0a14de978dd9a67d8ec04fc1891db875f6222b46',
  4: '0xFA0EcD385230C176dAF0f3295d6E12D39d5a7b1D'
}
export const treasuryByAddress: Address = {
  1: '0xba121458A45b37782C1a450081bf9cc3786fE8B2',
  4: '0xba121458A45b37782C1a450081bf9cc3786fE8B2'
}

export const rappearNftsByAddress: Address = {
  1: '0xfdd1148c433ae4e25522a2a0479f21eb43ac2f15',
  4: '0xd20b0Ac99B69029097608341262FF3a9cc410119'
}

export default rewardPoolByAddress;