export default {
  cake: {
    4: '0x9afF3dBB409AcE1B9441200E77183B2C6dE210CB',
    1: '0xf7ae26f1bf3c2312a4cf42246f947a4be25eef92', // made this XPLAY
  },
  syrup: {
    4: '',
    1: '0x85346d658909d93f31a232f60fc45454919d79dc', // changed to syrup contract in below masterchef contract
  },
  masterChef: {
    4: '',
    1: '0x33038334F54E97A8beb276BD528f0e6E55c1861D', // changed to masterchef for XPLAY
  },
  sousChef: {
    4: '',
    1: '0x6ab8463a4185b80905e05a9ff80a2d6b714b9e95',   
  },
  wbnb: {
    4: '0xc778417e063141139fce010982780140aa0cd5ab',
    1: '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2',
  },
  lottery: {
    4: '',
    1: '0x3C3f2049cc17C136a604bE23cF7E42745edf3b91',
  },
  lotteryNFT: {
    4: '',
    1: '0x5e74094Cd416f55179DBd0E45b1a8ED030e396A1',
  },
  mulltiCall: {
    1: '0x5BA1e12693Dc8F9c48aAD8770482f4739bEeD696',
    4: '0x5ba1e12693dc8f9c48aad8770482f4739beed696',
  },
  busd: {
    1: '0xe9e7cea3dedca5984780bafc599bd69add087d56',
    4: '',
  },
  ust: {
    1: '0x23396cF899Ca06c4472205fC903bDB4de249D6fC',
    4: '',
  },
  pancakeProfile: {
    1: '0xDf4dBf6536201370F95e06A0F8a7a70fE40E388a',
    4: '',
  },
  pancakeRabbits: {
    1: '0xDf7952B35f24aCF7fC0487D01c8d5690a60DBa07',
    4: '',
  },
  bunnyFactory: {
    1: '0xfa249Caa1D16f75fa159F7DFBAc0cC5EaB48CeFf',
    4: '',
  },
  eth: {
    1: '0x2170ed0880ac9a755fd29b2688956bd959f933f8',
    4: '',
  },
  claimRefund: {
    1: '0xE7e53A7e9E3Cf6b840f167eF69519175c497e149',
    4: '',
  },
  pointCenterIfo: {
    1: '0x3C6919b132462C1FEc572c6300E83191f4F0012a',
    4: '',
  },
  bunnySpecial: {
    1: '0xFee8A195570a18461146F401d6033f5ab3380849',
    4: '',
  },
}
