import { BigNumber, formatFixed } from "@ethersproject/bignumber";
import BigNumberJS from "bignumber.js";
import rewardPoolAbi from "config/abi/rewardPool.json";
import { getAddress } from "utils/addressHelpers";
import { getContract } from "utils/contractHelpers";

import rewardPoolByAddress, {
  treasuryByAddress,
} from "config/constants/rewardpool";

import { getWeb3NoAccount } from "utils/web3";

export const fetchRewardPool = async () => {
  // Pool Info
  const rewardPoolContract = getContract(
    rewardPoolAbi,
    getAddress(rewardPoolByAddress),
  );
  const poolInfo = await rewardPoolContract.methods.pool().call();

  // Treasury Info
  const web3 = getWeb3NoAccount();
  const treasuryBalance = await web3.eth.getBalance(
    getAddress(treasuryByAddress),
  );

  
  const returnVal = {
    rewardsPaidOut: poolInfo.cumulativeRewards,
    treasuryHoldings: treasuryBalance,
    numberOfMusicTracksHeld: 0,
    registeredNfts: poolInfo.totalTokensRegistered,
  };

  return returnVal;
};

export default fetchRewardPool;
