import React from "react";
import styled, { keyframes } from "styled-components";
import Page from "./layout/Page";

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  z-index: 1000;
  background: ${({ theme }) => theme.colors.background};
  overflow:hidden;
`;

const LoaderAnimation = keyframes`
{
  0% {
    transform: translate(-50%,-50%) scale(0.1);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    transform: translate(-50%,-50%) scale(1.2);
    opacity: 0;
  }

`;
const spin = keyframes`
  from{
    transform:rotate(0deg)
  }
  to{
    transform:rotate(360deg)
  }
`
const spinReverse = keyframes`
  from{
    transform:rotate(360deg)
  }
  to{
    transform:rotate(0deg)
  }
`
const Loader = styled.div`
  width: calc(3.23625vw + 150.86408px);
  height: calc(3.23625vw + 150.86408px);
  position: absolute;
  background: ${({ theme }) => theme.colors.primary};
  display: block;
  border-radius: 50%;
  z-index: 999;
  animation: ${LoaderAnimation} 1.5s ease-out infinite 0s;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transform-origin: center;
`;
const SVG = styled.svg`
  z-index: 1000;
  width: calc(3.23625vw + 50.86408px);
  height: calc(3.23625vw + 50.86408px);
   path:first-child{
    animation: ${spin} 2s linear infinite;
    transform-origin: center;
   }
   path:nth-child(2){
    animation: ${spinReverse} 2s linear infinite;
    transform-origin: center;
   }
   path:nth-child(3){
    animation: ${spin} 2s linear infinite;
    transform-origin: center;
   }
`;
const PageLoader: React.FC = () => {
  return (
    <Wrapper>
      <SVG
        version="1.1"
        id="L7"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        viewBox="0 0 100 100"
        enableBackground="new 0 0 100 100"
      >
        <path
          fill="#fff"
          d="M31.6,3.5C5.9,13.6-6.6,42.7,3.5,68.4c10.1,25.7,39.2,38.3,64.9,28.1l-3.1-7.9c-21.3,8.4-45.4-2-53.8-23.3
  c-8.4-21.3,2-45.4,23.3-53.8L31.6,3.5z"
        />
        <path
          fill="#fff"
          d="M42.3,39.6c5.7-4.3,13.9-3.1,18.1,2.7c4.3,5.7,3.1,13.9-2.7,18.1l4.1,5.5c8.8-6.5,10.6-19,4.1-27.7
  c-6.5-8.8-19-10.6-27.7-4.1L42.3,39.6z"
        />
         
        <path
          fill="#fff"
          d="M82,35.7C74.1,18,53.4,10.1,35.7,18S10.1,46.6,18,64.3l7.6-3.4c-6-13.5,0-29.3,13.5-35.3s29.3,0,35.3,13.5
  L82,35.7z"
        />
         
      </SVG>
    </Wrapper>
  );
};

export default PageLoader;
