/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";
import { farmsV2Config } from "config/constants/farms";
import BigNumber from "bignumber.js";
import { fetchFarmsV2 } from "./fetchFarmsV2";

import { FarmsV2State, FarmV2 } from "../types";

const initialState: FarmsV2State = {
  data: [...farmsV2Config],
  rewardTokenPerBlock: "0",
};

export const farmsV2Slice = createSlice({
  name: "FarmsV2",
  initialState,
  reducers: {
    setFarmsV2PublicData: (state, action) => {
      const liveFarmsData: FarmV2[] = action.payload;
      state.data = state.data.map((farm) => {
        const liveFarmData = liveFarmsData.find(
          (f) => f.lpSymbol === farm.lpSymbol,
        );
        return { ...farm, ...liveFarmData };
      });
    },
    setFarmV2PublicData: (state, action) => {
      const rewardTokenPerBlock: string = action.payload;
      state.rewardTokenPerBlock = rewardTokenPerBlock;
    },
    setFarmV2UserData: (state, action) => {
      const { arrayOfUserDataObjects } = action.payload;
      arrayOfUserDataObjects.forEach((userDataEl) => {
        const { index } = userDataEl;
        state.data[index] = { ...state.data[index], userData: userDataEl };
      });
    },
  },
});

// Actions
export const { setFarmsV2PublicData, setFarmV2UserData, setFarmV2PublicData } =
  farmsV2Slice.actions;

// Thunks
export const fetchFarmsV2PublicDataAsync = () => async (dispatch) => {
  const farms = await fetchFarmsV2();
  dispatch(setFarmsV2PublicData(farms));
};

export default farmsV2Slice.reducer;
